import { MetaSEO } from "src/components";
import EditablePostForm from "../../components/EditablePostForm/EditablePostForm";
import { usePostsContext } from "../../context/PostsProvider/PostsProvider";
import usePost from "../usePost";

function EditPost(props) {
  const posts = usePostsContext();
  const { post } = usePost(posts);

  return (
    <>
      <MetaSEO title={`Edit Post - ${post.title}`} description="Edit post" />
      <EditablePostForm post={post} />
    </>
  );
}

export default EditPost;
